<template>
  <div>
    <a-modal
        v-model="showModal"
        :keyboard="false"
        :bodyStyle='{
        padding: "10px",
      }'
        :width='1400'
        @cancel='cancel'
    >
      <div class="content">
        <div class="con-body">
          <div class="c-right">
            <div
                class="image-view"
                v-for="(item, index) in showImages"
                :class="{
                bg: item.type == 1
              }"
                :key="index"
            >
              <template v-if="item.showStatus">
                <div class="c-info">
                  <img :src="versionImage" @click="previewImg" />
                  <!-- <div  class="item-info" v-if="recordList[index].versionDesc"> -->
                  <div  class="item-info" >
                    <a-popover
                        v-if="false"
                        title="版别描述"
                        trigger="hover"
                    >
                      <template slot="content">
                        <!-- <p>{{recordList[index].versionDesc}}</p> -->
                        <!-- <textarea></textarea> -->
                        <a-input
                            size="small"
                            type="textarea"
                            placeholder="版别介绍"
                            :rows="5"
                            @change="inputVersionDesc"
                            :value="recordList[index].versionDesc"
                        ></a-input>
                        <a-button
                            style="margin-left:10px;"
                            size="small"
                            type="primary"
                            class="setup-btn"
                            @click="editVersionDesc(recordList[index].versionId)"
                        >修改
                        </a-button>
                      </template>

                      <div v-if="recordList[index].versionDesc">{{recordList[index].versionDesc.substr(0,11)}}</div>
                      <div v-if="!recordList[index].versionDesc">版别介绍编辑</div>

                    </a-popover>
                    <div>{{recordList[index].itemName + " " + recordList[index].versionName}}</div>
                  </div>
                </div>
                <div
                    class="image-item"
                    v-for="(item2, index2) in item.list"
                    :key="index2"
                >
                  <div class="image-box">
                    <img
                        class="image"
                        :src="item2.url"
                    />
                    <div class="image-box-fixed2">
                      <div>{{item2.ranking}}</div>
                    </div>
                    <div class="image-box-fixed">
                      <div>{{item2.id}}</div>
                      <div>{{item2.distance}}</div>
                    </div>
                  </div>

                  <div class="item-info">
                    <div>{{item2.versionName}}</div>
                    <div v-if="pageType != 1">{{item2.tagNames | tagNameFilter}}</div>
                  </div>

                </div>

                <div class="btn-box-center">
                  <div
                      class="item-btn"
                      v-if="pageType === 1"
                  >
                    <a-button
                        size="small"
                        type="primary"
                        class="setup-btn"
                        @click="partScorePage(recordList[index].recordId,recordList[index].versionId,index, 'up')"
                    >上一组</a-button>
                  </div>

                  <div
                      class="item-btn"
                      v-if="pageType === 1"
                      style="margin-top: 20px;"
                  >
                    <a-button
                        size="small"
                        type="primary"
                        class="setup-btn"
                        @click="partScorePage(recordList[index].recordId, recordList[index].versionId,index, 'down')"
                    >下一组</a-button>
                  </div>
                </div>

                <div class="delete-current-img-list ml-20 mt-60">
                  <a-button size="small" icon="close-circle" @click="handleDeleteCurrentImgList(index)"></a-button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="footer">
          <div>
            <a-radio-group
                v-model="imageType"
                :default-value="0"
                button-style="solid"
            >
              <a-radio-button :value="0">
                正面图
              </a-radio-button>
              <a-radio-button :value="1">
                背面图
              </a-radio-button>
            </a-radio-group>

            <!-- 选择小币种 -->
            <a-select
                :dropdownMatchSelectWidth="false"
                @change="changeCoinItem"
                :filter-option="untils.filterOption"
                allowClear
                showSearch
                size="small"
                style="margin: 5px; width: 180px"
                v-model="curItem.coinKindId"
                placeholder="请选择小币种"
            >
              <a-select-option
                  v-for="item of coinItemList"
                  :key="item.id"
                  :value="item.id"
              >{{ item.coinKindItemName }}</a-select-option>
            </a-select>
            <!-- 选择版别 -->
            <a-select
                :dropdownMatchSelectWidth="false"
                allowClear
                @change="changeVersion"
                showSearch
                :filter-option="untils.filterOption"
                size="small"
                style="margin: 5px; width: 180px"
                v-model="curItem.versionId"
                :dropdownMenuStyle="{'max-height': '500px'}"
                placeholder="版别"
            >
              <a-select-option
                  v-for="item of versionList"
                  :key="item.id"
                  :value="item.id"
              >{{ `${item.coinKindVersionName}` }}</a-select-option>
            </a-select>
            <span class="refactor-version">
              <a-button size="small" type="primary" @click="handleRefactorVersion()">重新对版</a-button>
            </span>
            <span class="refactor-version">
              <a-button size="small" type="primary" @click="handleGetMoreVersion()">更多版别</a-button>
            </span>
          </div>
          <div class="footer-btn">
            <a-button @click="cancel()">关闭</a-button>
          </div>
        </div>

      </template>
    </a-modal>
  </div>

</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      imageType: 0,
      curItem: {},
      originalInfo: {},
      recordList: [],
      versionId: "",
      tempVersionId: '',
      pageType: 0,
      coinItemList: [],
      versionList: [],
      kindVersionIds: [],
      changeTestCheckBox: 0,
      frontOrBackList: [],
      frontCollect: 0,
      backCollect: 0,
      currentVersionDesc: "",
      listPageNumber: {}
    };
  },
  computed: {
    versionImage() {
      const { versionFrontImage, versionBackImage } = this.originalInfo;
      return this.imageType == 0 ? versionFrontImage : versionBackImage;
    },
    showImages() {
      return this.recordList.map((el) => {
        const list = el.list.map((el2) => {
          return {
            ...el2,
            url:
                this.imageType == 0
                    ? el2.versionFrontImage
                    : el2.versionBackImage,
          };
        });
        return {
          ...el,
          list,
        };
      });
    },
  },
  filters: {
    tagNameFilter(val) {
      return val.replaceAll(",", " | ");
    },
  },
  methods: {
    /** 删除数据 */
    handleDeleteCurrentImgList(index) {
      this.recordList.forEach((item, ind) => {
        if (ind === index) {
          item.showStatus = false
        }
      })
    },
    /** 上一组，下一组 */
    async partScorePage(recordId, versionId, index, type){
      if (type === 'up') {
        this.listPageNumber[index] = this.listPageNumber[index] === undefined
            ? 0
            : this.listPageNumber[index] === 0
                ? 0 : this.listPageNumber[index] - 1
      } else {
        this.listPageNumber[index] = this.listPageNumber[index] === undefined
            ? 1
            : this.listPageNumber[index] + 1
      }
      this.$loading.show();
      const res = await this.axios("/dq_admin/duibanUserRecord/partConfirmDataScorePage", {
        params: {
          recordId: recordId,
          versionId: versionId,
          pageNum: this.listPageNumber[index]
        },
      });
      this.$loading.hide()
      if (res.status !== '200') {
        this.$message.success(res.message || '加载失败')
      }
      if (res.status === '200') {
        // this.$message.success(res.message || '加载成功')
        this.recordList.forEach((item, ind) => {
          if (ind === index) {
            item.list = res.data
          }
        })
      }
    },
    /** 获取更多版别 */
    async handleGetMoreVersion() {
      try {
        const found = this.recordList.find(el => el.type === 1).versionId
        this.kindVersionIds = this.kindVersionIds.filter(el => el != found)
      } catch (e) {console.log('1')}
      const kindVersionIdsStr = this.kindVersionIds.join(',')
      const res = await this.axios('/dq_admin/duibanUserRecord/rubbingByNextVersion', {
        params: {
          recordId: this.curItem.id,
          versionIds: kindVersionIdsStr
        }
      })
      if (res.status === '200') {
        this.kindVersionIds.push(res.data.versionId)
        res.data.showStatus = true
        this.recordList.forEach(el => {
          el.showStatus = true
        })
        this.recordList.push(res.data)
      }
    },
    //编辑版别描述
    async editVersionDesc(versionId) {
      const res = await this.axios.post("/dq_admin/kinditemversion/singleEditVersion",
          {
            id: versionId,
            versionDesc: this.currentVersionDesc
          }
      )
      // this.$loading.hide();
      if (res.status !== '200') return;
      this.$message.success("更改成功");
    },

    inputVersionDesc(e){
      // console.log("xxx");
      // console.log(e.target.value);
      this.currentVersionDesc = e.target.value;
    },

    /** 重新对版 */
    handleRefactorVersion() {
      this.getInfo(1)
    },
    async show(data, type, versionId) {
      this.pageType = type;
      this.curItem = JSON.parse(JSON.stringify(data));
      this.tempVersionId = data.versionId
      this.versionId = versionId || "";
      this.imageType = 0;
      await this.getInfo(0);
      await this.coinItemSearch();
      this.changeCoinItem(this.curItem.coinKindId)
      this.showModal = true;
    },
    // 预览图片
    previewImg(){
      const list = [{
        img_url: this.versionImage
      }];
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    success() {
      this.getInfo(0);
    },
    // 默认值
    async getInfo(type) {
      this.$loading.show();
      const res = await this.axios("/dq_admin/duibanUserRecord/rubbing", {
        params: {
          recordId: this.curItem.id,
          questionId: this.curItem.questionId,
          type
        },
      });
      this.$loading.hide();
      if (res.status !== '200') return;
      const { originalInfo, recordList } = res.data;
      recordList.forEach(el => {
        el.showStatus = true
      })
      this.originalInfo = JSON.parse(JSON.stringify(originalInfo));
      this.recordList = JSON.parse(JSON.stringify(recordList));
      this.kindVersionIds = recordList.map(el => el.versionId);
      this.images = res.data;
    },
    //  选择小币种
    changeCoinItem(val) {
      delete this.curItem.versionId;
      if (!val) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id === val;
      });
      this.versionList = coinItem.coinItemVersionList;
      this.changeVersion(this.tempVersionId)
    },
    // 版别改变
    changeVersion(val) {
      this.tempVersionId = val
      if (!val) return;
      const version = this.versionList.find((el) => {
        return el.id == val;
      });
      this.curItem.versionId = version.id;
      this.getRubbing();
    },
    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    //
    async getRubbing() {
      const res = await this.axios(
          "/dq_admin/duibanUserRecord/rubbingByVersionId",
          {
            params: {
              recordId: this.curItem.id,
              versionId: this.tempVersionId,
              questionId: this.curItem.questionId
            },
          }
      );
      if (res.status != 200) return;
      this.kindVersionIds.push(res.data.versionId)
      res.data.showStatus = true
      this.recordList.forEach(el => {
        el.showStatus = true
      })
      this.recordList.push(res.data);
    },
    // 关闭
    cancel() {
      this.showModal = false;
      this.originalInfo = {};
      this.recordList = [];
      this.frontOrBackList = [];
      this.frontCollect = 0;
      this.backCollect = 0;
      this.currentVersionDesc = "";
      this.listPageNumber = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-box-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-btn {
  margin-right: 20px;
  button {
    width: 240px;
  }
}
.body-top {
  display: flex;
  justify-items: flex-start;
  align-items: center;

}
.go-to {
  color: #1890ff !important;
  cursor: pointer !important;
  text-decoration: underline;
}
.refactor-version {
  margin-left: 20px;
}
.con-body {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  padding-top: 10px;
}
.c-info {
  margin-right: 10px;
  margin-bottom: 10px;
  height: 100%;
  img {
    width: 200px;
    height: 200px;
  }
}
.image-view {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &.bg {
    background: #e6f7ff;
  }
  .image-item {
    width: 200px;
    height: 200px;
    margin-right: 10px;
  }
  .image-box {
    width: 200px;
    height: 200px;
    text-align: center;
    position: relative;
    .image {
      height: 200px;
      width: 200px;
    }
    .image-box-fixed {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      font-weight: bold;
      padding: 0 6px;
    }
    .image-box-fixed2 {
      position: absolute;
      right: 0;
      top: 0;
      width: 10%;
      display: flex;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.3);
      color: rgb(234, 27, 27);
      font-weight: bold;
      padding: 0 6px;
    }
  }
  .item-info {
    margin-bottom: 5px;
    text-align: center;
    & > div {
      margin-top: 2px;
    }
  }
}
.item-btn {
  display: flex;
  align-items: center;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::v-deep .ant-modal {
  top: 50%;
  transform: translateY(-50%);
}
::v-deep .ant-modal-footer {
  padding: 10px 7px;
}
.dis-flex-start {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mt-10 {
  margin-top: 10px;
}
.delete-current-img-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-20 {
  margin-left: 20px;
}
.mt-60 {
  margin-top: 0px;
}
</style>