var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          },
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "questionId",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.questionId))]),
                _c(
                  "div",
                  {
                    staticClass: "link-text",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToVersionList(row.duibanId)
                      }
                    }
                  },
                  [_vm._v("(" + _vm._s(row.duibanId) + ")")]
                ),
                row.sameId
                  ? _c(
                      "div",
                      {
                        staticClass: "color-red cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.handleSameCurrencyList(row)
                          }
                        }
                      },
                      [_vm._v("（" + _vm._s(row.sameId) + "）")]
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemImage",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "item-image-list" }, [
                  _c("img", {
                    staticClass: "item-image",
                    attrs: { src: row.showImageUrl },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImage(row, 0)
                      }
                    }
                  }),
                  _c("img", {
                    staticClass: "item-image",
                    attrs: { src: row.revealImageUrl },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImage(row, 1)
                      }
                    }
                  })
                ]),
                row.duibanId
                  ? _c(
                      "div",
                      { staticClass: "mt-10" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleResetVersion(row)
                              }
                            }
                          },
                          [_vm._v("重新对版")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemVersionTop",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.machineList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "version-top",
                      class: item.bgColor
                    },
                    [
                      _vm._v(
                        " [" +
                          _vm._s(item.dikuVersionCount) +
                          "]" +
                          _vm._s(
                            item.itemName +
                              "·" +
                              item.versionName +
                              "（" +
                              item.distance +
                              "）"
                          ) +
                          " "
                      )
                    ]
                  )
                }),
                0
              )
            }
          },
          {
            key: "itemQuestionSourceTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { class: _vm.sourceTypeClassType[row.sourceType] }, [
                  _vm._v(_vm._s(_vm.computedSourceTypeMapText(row.sourceType)))
                ])
              ])
            }
          },
          {
            key: "itemConfirmVersion",
            fn: function(row) {
              return _c("div", { staticClass: "color-green" }, [
                row.confirmItemName || row.confirmVersionName
                  ? _c("div", [
                      row.confirmItemName
                        ? _c("span", [_vm._v(_vm._s(row.confirmItemName))])
                        : _vm._e(),
                      row.confirmItemName
                        ? _c("span", [_vm._v("·")])
                        : _vm._e(),
                      row.confirmVersionName
                        ? _c("span", [_vm._v(_vm._s(row.confirmVersionName))])
                        : _vm._e()
                    ])
                  : _c("div", [_vm._v("-")])
              ])
            }
          },
          {
            key: "itemAnswerList",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c("a-table", {
                    attrs: {
                      columns: _vm.sonTableList,
                      "data-source": row.answers,
                      pagination: false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "sonItemAnswerStatus",
                          fn: function(sonRow) {
                            return _c("div", {}, [
                              _c("span", {}, [
                                _vm._v(
                                  _vm._s(
                                    sonRow.answerType === "正常回答"
                                      ? "正常类型"
                                      : sonRow.answerType
                                      ? sonRow.answerType
                                      : "-"
                                  )
                                )
                              ])
                            ])
                          }
                        },
                        {
                          key: "sonItemTagType",
                          fn: function(sonRow) {
                            return _c("div", {}, [
                              sonRow.tagStatus === -1
                                ? _c("span", { staticClass: "color-red" }, [
                                    _vm._v("假币")
                                  ])
                                : sonRow.tagStatus === 2
                                ? _c("span", { staticClass: "color-red" }, [
                                    _vm._v("看不清")
                                  ])
                                : sonRow.tagStatus === 4
                                ? _c("span", { staticClass: "color-red" }, [
                                    _vm._v("不是本身")
                                  ])
                                : sonRow.tagStatus === 6
                                ? _c("span", { staticClass: "color-red" }, [
                                    _vm._v("图片不规范")
                                  ])
                                : _c("span", [_vm._v("-")])
                            ])
                          }
                        },
                        {
                          key: "sonItemAnswerUserNameSlot",
                          fn: function(sonRow) {
                            return _c(
                              "div",
                              {},
                              [
                                _c(
                                  "a-tooltip",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function() {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    sonRow.answerUserName +
                                                      " " +
                                                      sonRow.answerUserId
                                                  )
                                                )
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "cur-pot color-blue" },
                                      [_vm._v(_vm._s(sonRow.answerUserId))]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "sonItemVersionResult",
                          fn: function(sonRow) {
                            return _c("div", { staticClass: "color-blue" }, [
                              sonRow.answerItemName || sonRow.answerVersionName
                                ? _c("div", [
                                    sonRow.answerItemName
                                      ? _c("span", [
                                          _vm._v(_vm._s(sonRow.answerItemName))
                                        ])
                                      : _vm._e(),
                                    sonRow.answerItemName
                                      ? _c("span", [_vm._v("·")])
                                      : _vm._e(),
                                    sonRow.answerVersionName
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(sonRow.answerVersionName)
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _c("div", [_vm._v("-")]),
                              row.sourceType === 80
                                ? _c(
                                    "div",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleChangeRatingDuibanVersionName(
                                                row,
                                                sonRow
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("修改评级确版")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          }
                        },
                        {
                          key: "sonItemAction",
                          fn: function(sonRow) {
                            return _c("div", {}, [
                              _c(
                                "div",
                                { staticClass: "action-btn" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "setup-btn mt-10",
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        disabled:
                                          !!row.confirmVersionName ||
                                          !sonRow.answerVersionName
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAuctionBtn(
                                            row,
                                            2,
                                            sonRow
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("确认到样本库")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "setup-btn mt-10",
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        disabled:
                                          !!row.confirmVersionName ||
                                          !sonRow.answerVersionName
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAuctionBtn(
                                            row,
                                            3,
                                            sonRow
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("确认到标库 "),
                                      sonRow.dikuVersionCount
                                        ? _c("span", [
                                            _vm._v(
                                              "[" +
                                                _vm._s(
                                                  sonRow.dikuVersionCount
                                                ) +
                                                "]"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }
          },
          {
            key: "itemOperateStatus",
            fn: function(row) {
              return _c("div", {}, [
                row.operateStatus === 0 || row.operateStatus == null
                  ? _c("span", { staticClass: "color-red" }, [_vm._v("待处理")])
                  : _vm._e(),
                row.operateStatus === -1
                  ? _c("span", { staticClass: "color-orange" }, [
                      _vm._v("搁置")
                    ])
                  : _vm._e(),
                row.operateStatus === 10
                  ? _c("span", [_vm._v("已确认进样本库")])
                  : _vm._e(),
                row.operateStatus === 20
                  ? _c("span", [_vm._v("已确认进标库")])
                  : _vm._e(),
                row.operateStatus === 4
                  ? _c("span", { staticClass: "color-6699ff" }, [
                      _vm._v("假币")
                    ])
                  : _vm._e(),
                row.operateStatus === 6
                  ? _c("span", { staticClass: "color-6699ff" }, [
                      _vm._v("无法分辨")
                    ])
                  : _vm._e(),
                row.operateStatus === 3
                  ? _c("span", { staticClass: "color-gray" }, [
                      _vm._v("已处理")
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "action",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "action-btn" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "setup-btn",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleAuctionBtn(row, 1)
                          }
                        }
                      },
                      [_vm._v("原图对版")]
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定假币吗？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleFalseCoinClick(row, 4)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "setup-btn mt-10",
                            attrs: { size: "small" }
                          },
                          [_vm._v("假币")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定吗？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleFalseCoinClick(row, 6)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "setup-btn mt-10",
                            attrs: { size: "small" }
                          },
                          [_vm._v("无法辨认")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定搁置吗？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleAuctionBtn(row, 4)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "setup-btn mt-10",
                            attrs: { size: "small" }
                          },
                          [_vm._v("搁置")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定标记已处理吗？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleFalseCoinClick(row, 3)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "setup-btn mt-10",
                            attrs: { size: "small" }
                          },
                          [_vm._v("已处理")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("PictureVersionPopup", { ref: "pictureVersionPopupEl" }),
      _c("PictureVersionPopupV2", { ref: "pictureVersionPopupV2El" }),
      _c("PushVersionStorePopup", {
        ref: "pushVersionStorePopupEl",
        on: { success: _vm.handleStoreSuccess }
      }),
      _c("PushVersionSourceStorePopup", {
        ref: "pushVersionSourceStorePopupEl",
        on: { success: _vm.handleStoreSuccess }
      }),
      _c("AgainChangeRatingVersionPopup", {
        ref: "againChangeRatingVersionPopupEl",
        on: { success: _vm.handleStoreSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }