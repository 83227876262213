<script>
import {getBigCoinList} from "@/axios/apis"

export default {
  methods: {
    /** 获取大币种列表 */
    async searchData(inner){
      if (!inner) return
      const res = await getBigCoinList(inner, [5])
      if (res.status !== '200') return;
      this.bigCoinList = res.data.records
    },
    /** 选择大币种 */
    handleChangeCoin(e) {
      this.bigCoinText = this.bigCoinList.find(el => el.id === e).coinKindName
      delete this.params.coinKindItemId;
      delete this.params.coinVersionId
      if (!e) {
        this.bigCoinList = []
        this.smallCoinList = [];
        this.versionList = [];
        return;
      }
      this.getSmallCoinList();
    },
    /** 查询小币种 */
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinItems", {
        params: {
          coinId: this.params.coinKindId,
        },
      });
      if (res.status != 200) return;
      this.smallCoinList = res.data;
    },
    /** 选择小币种 */
    coinItemChange(val) {
      delete this.params.coinVersionId;
      if (!val) {
        this.versionList = [];
        return;
      }
      this.getVersionType();
    },
    /** 获取版别 */
    async getVersionType() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinVersions", {
        params: {
          coinId: this.params.coinKindId,
          coinItemId: this.params.coinKindItemId,
        },
      });
      if (res.status != 200) return;
      this.versionList = res.data;
    },
  }
}
</script>