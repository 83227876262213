<template>
  <!-- 推送地库 -->
  <a-modal
      v-model="pushModal"
      :width='800'
      title="确认到标库"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelPushModal"
      @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin($event, 'change')"
            @search="coinSearch($event, '', [5])"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.coinKindId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.coinKindItemId"
            placeholder="请选择小币种"
        >
          <a-select-option
              v-for="item of coinItemList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 280px"
            v-model="params.versionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.coinVersionId"
              :value="item.coinVersionId"
          >{{ `${item.coinVersionName}【${item.size}】【${item.level}】` }}</a-select-option>
        </a-select>
      </div>
    </div>

  </a-modal>
</template>

<script>
import untils from "@/untils"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      untils,
      pushModal: false,
      params: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
    };
  },
  methods: {
    async show(data) {
      this.params = JSON.parse(JSON.stringify(data));
      this.$loading.show()
      await this.setDefault();
      this.$loading.hide()
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      await this.coinSearch(this.params.coinKindName, 'setDefault', [5])
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      const {duibanId, versionId, questionId} = this.params
      const res = await this.axios(
          "/dq_admin/duibanUserRecord/pushAsynDiku",
          {
            params: {
              recordId: duibanId,
              versionId: versionId,
              questionId: questionId,
              isTest: 0
            },
          }
      );
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$emit('success')
      this.$message.success(res.message || "操作成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.checked {
  margin-top: 10px;
}
</style>